// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-baked-dwarves-tsx": () => import("./../src/pages/press/baked-dwarves.tsx" /* webpackChunkName: "component---src-pages-press-baked-dwarves-tsx" */),
  "component---src-pages-press-essential-trips-tsx": () => import("./../src/pages/press/essential-trips.tsx" /* webpackChunkName: "component---src-pages-press-essential-trips-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-press-mobius-ship-tsx": () => import("./../src/pages/press/mobius-ship.tsx" /* webpackChunkName: "component---src-pages-press-mobius-ship-tsx" */),
  "component---src-pages-press-number-two-tsx": () => import("./../src/pages/press/number-two.tsx" /* webpackChunkName: "component---src-pages-press-number-two-tsx" */),
  "component---src-pages-press-quintessence-tsx": () => import("./../src/pages/press/quintessence.tsx" /* webpackChunkName: "component---src-pages-press-quintessence-tsx" */),
  "component---src-pages-press-we-have-a-problem-tsx": () => import("./../src/pages/press/we-have-a-problem.tsx" /* webpackChunkName: "component---src-pages-press-we-have-a-problem-tsx" */)
}

